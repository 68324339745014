import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "./footer-menu.scss"

const FooterMenu = (props) => {
  return (
    <div className='footer-menu'>
        <Nav {...props} >
    <Nav.Link as={Link} to="/" style={{color:"#c9c6c6"}}>
      Anasayfa
    </Nav.Link>
    <Nav.Link as={Link} to="/galeri" style={{color:"#c9c6c6"}}>
       Galeri
    </Nav.Link>
    <Nav.Link as={Link} to="/yenilikler" style={{color:"#c9c6c6"}}>
       Yenilikler
    </Nav.Link>
    <Nav.Link as={Link} to="/blog" style={{color:"#c9c6c6"}}>
       Blog
    </Nav.Link>
    <Nav.Link as={Link} to="/hakkimda" style={{color:"#c9c6c6"}}>
       Hakkımda
    </Nav.Link>
   
  </Nav>
    </div>
  )
}

export default FooterMenu