import { RouterProvider, createBrowserRouter } from "react-router-dom";
import UserLayout from "../layouts/user-layout";
import HomePage from "../pages/home-page";
import BizeUlasin from "../pages/bize-ulasin";
import ResentsPage from "../pages/resents-page";
import YeniliklerPage from "../pages/yenilikler-page";
import GalleryPage from "../pages/gallery-page";
import Fotograflar from "../components/gallery/fotograflar";
import Videolar from "../components/gallery/videolar";
import LoginForm from "../components/admin-panel/dashboard/login-form";
import ArticleTable from "../components/admin-panel/dashboard/blog-table";
import AdminGaleri from "../components/admin-panel/dashboard/admin-galeri";
import AdminYenilikler from "../components/admin-panel/dashboard/admin-yenilikler";
import DashboardPage from "../components/admin-panel/dashboard/dashboard-page";
import PrivateRoute from "./private-route";
import { config } from "../helpers/config";
import HakkimdaPage from "../pages/hakkimda-page";
import AdminsGaleri from "../components/admin-panel/dashboard/admins-galeri";
import Dashboard from "../components/admin-panel/dashboard/dashboard-page";
import BlogTable from "../components/admin-panel/dashboard/blog-table";

const router = createBrowserRouter([
  {
    path: "/",
    element: <UserLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "galeri",
        element: <GalleryPage />,
      },
      {
        path: "iletisim",
        element: <BizeUlasin />,
      },
      {
        path: "yenilikler",
        element: <YeniliklerPage />,
      },
      {
        path: "blog",
        element: <ResentsPage />,
      },
      {
        path: "photos",
        element: <Fotograflar />,
      },
      {
        path: "videos",
        element: <Videolar />,
      },
      {
        path: "login",
        element: <LoginForm />,
      },
      {
        path: "hakkimda",
        element: <HakkimdaPage />,
      },

      {
        path: "dashboard",
        /* element: <PrivateRoutes />, */
        element: <DashboardPage />,
      },

      {
        path: "dashboard/admin-galeri",
        element: <AdminsGaleri />,
      },
      {
        path: "dashboard/admin-yenilikler",
        element: <AdminYenilikler />,
      },
      {
        path: "dashboard/blog-table",
        element: <BlogTable />,
      },

      /*  {
        path: "dashboard-page",
        element: 
        [
          {
            index: true,
            element: (
              <PrivateRoute roles={config.pageRoles.dashboard}>
                <DashboardPage />
              </PrivateRoute>
            ),
          }
        ]} 
 */
    ],
  },
]);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
