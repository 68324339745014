import React from "react";
import Gallery from "../components/gallery/gallery";
import Spacer from "../components/common/spacer";
import BackgroundLogo from "../components/common/background-logo";
import PageHeader from "../components/common/page-header";


const GalleryPage = () => {
  return (
    <>
 
      <BackgroundLogo />
      <PageHeader title=" GALERİ " />
      <Gallery />
      <Spacer />
    </>
  );
};

export default GalleryPage;
