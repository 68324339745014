import React from "react";
import { Nav } from "react-bootstrap";
import { config } from "../../helpers/config";
import {
  FaFacebook,
  FaTwitter,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";

const SocialMenu = (props) => {
  return (
    <Nav {...props}>
      <Nav.Link href={config.contact.socialMedia.facebook} target="_blank" style={{color:"#c9c6c6"}}>
        <FaFacebook /> Facebook
      </Nav.Link>
      <Nav.Link href={config.contact.socialMedia.twitter} target="_blank" style={{color:"#c9c6c6"}}>
        <FaTwitter /> Twitter
      </Nav.Link>
      <Nav.Link href={config.contact.socialMedia.instagram} target="_blank" style={{color:"#c9c6c6"}}>
        <FaInstagram /> Instagram
      </Nav.Link>
      <Nav.Link href={config.contact.socialMedia.youtube} target="_blank" style={{color:"#c9c6c6"}}>
        <FaYoutube /> Youtube
      </Nav.Link>
     
    </Nav>
  );
};

export default SocialMenu;
