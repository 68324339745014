import React from "react";
import "./youtube.scss";

const Youtube = ({ youtubeVideoLink }) => {
  // YouTube embed kodunu alın
  const embedCode = getYouTubeEmbedCode(youtubeVideoLink);
  return (
    <div>
      {/* YouTube videosunu gömün */}
      <div className="youtube">
        <div dangerouslySetInnerHTML={{ __html: embedCode }} />
      </div>
    </div>
  );
};

// YouTube video linkinden embed kodunu almak için yardımcı fonksiyon
const getYouTubeEmbedCode = (videoLink) => {
  
  const videoId = videoLink.split("v=")[1];
  console.log(videoId)
  return `<iframe width="370" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

  
};

export default Youtube;
