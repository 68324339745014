import React, { useEffect, useState } from "react";
import "./blog-tabs.scss";
import PageHeader from "../../common/page-header";
import axios from "axios";
import { config } from "../../../helpers/config";
import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";



const API_URL = config.api.baseUrl;

const BlogTable = () => {
  // Makalelerin tutulacağı state
  /*   const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
};
 */
  const [selectedFile, setSelectedFile] = useState(null);
  const [articles, setArticles] = useState([]);
  const [value, setValue] = useState(
    sessionStorage.getItem("isLoggedIn") === "true" ? "true" : ""
  );
  // Eklenecek yeni makalenin geçici olarak tutulacağı state
  const [newArticle, setNewArticle] = useState({
    day: "",
    baslik: "",
    image: "",
    name: "",
    desc: "",
  });

  

  
  const navigate = useNavigate();

  useEffect(() => {
    if (value !== "true") {
      navigate("/login");
      return;
    }
  }, []);



  // Düzenleme yapılacak makalenin index değerini tutacak state
  const [editIndex, setEditIndex] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setNewArticle((prevArticle) => ({
      ...prevArticle,
      image: e.target.files[0].name,
    }));
  };

  const deleteUser = async (id, imageName) => {
    try {
      const response = await axios.post(
        `${API_URL}/?type=remove_photo&param=${imageName}`
      );
      console.log(response.data);

      const secondResponse = await axios.delete(
        `${API_URL}/?type=articles&param=${id}`
      );
      getUsers();
      console.log(secondResponse.data);
    } catch (error) {
      console.error(
        "Dosya yüklenirken veya path dbye yazılırken bir hata oluştu:...",
        error
      );
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  function getUsers() {
    axios.get(`${API_URL}/?type=articles`).then(function (response) {
      console.log(response.data);
      setArticles(response.data);
    });
  }

  // Yeni makale eklemek için
  const addArticle = async () => {
    if (!selectedFile) {
      console.log("Lütfen bir dosya seçin.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(
        `${API_URL}/?type=upload_photo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      /*          onPhotoAdd(selectedFile);
       */

      const secondResponse = await axios.post(
        `${API_URL}/?type=articles`,
        newArticle
      );
      console.log(secondResponse.data);
      getUsers();
      setSelectedFile(null);

      setNewArticle({
        // Inputları boşalt
        day: "",
        baslik: "",
        image: "",
        name: "",
        desc: "",
      });

      window.location.reload();


    } catch (error) {
      console.error(
        "Dosya yüklenirken veya path dbye yazılırken bir hata oluştu:...",
        error
      );
    }
  };

  // Makale düzenlemek için
  const editArticle = (index) => {
    // Düzenleme yapılacak makalenin index değerini set et
    setEditIndex(index);
    // Düzenleme yapılacak makalenin bilgilerini geçici makaleye set et
    setNewArticle(articles[index]);
  };

  // Makaleyi güncellemek için
  const updateArticle = () => {
    // Düzenleme yapılan makaleyi güncelle
    articles[editIndex] = newArticle;
    // Güncellenmiş makale listesini set et
    setArticles([...articles]);
    // Düzenleme yapılacak makalenin index değerini sıfırla
    setEditIndex(null);
    // Geçici makale bilgilerini sıfırla
    axios
      .put(`${API_URL}/articles/${newArticle.id}/edit`, newArticle)
      .then(function (response) {
        console.log(response.data);
        getUsers();
      });
  };

  return (
    <div className="text-center">
      <PageHeader title="ADMİN BLOG" />
      <div className="article-form">
        <input
          type="file"
          id="fileInput"
          accept="image/*"
          onChange={handleFileChange}
        />
        <input
          type="text"
          placeholder="Title"
          value={newArticle.baslik}
          onChange={(e) =>
            setNewArticle({ ...newArticle, baslik: e.target.value })
          }
        />
       
        <input
          type="text"
          placeholder="Description"
          value={newArticle.desc}
          onChange={(e) =>
            setNewArticle({ ...newArticle, desc: e.target.value })
          }
        />
        
         <input
          type="text"
          placeholder="Day"
          value={newArticle.day}
          onChange={(e) =>
            setNewArticle({ ...newArticle, day: e.target.value })
          }
        />
        <input
          type="text"
          placeholder="Name"
          value={newArticle.name}
          onChange={(e) =>
            setNewArticle({ ...newArticle, name: e.target.value })
          }
        />
        {editIndex === null ? (
          <button style={{ backgroundColor: "green" }} onClick={addArticle}>
            Add
          </button>
        ) : (
          <button onClick={updateArticle}>Update</button>
        )}
      </div>

      <div className="article-table-container">
        {" "}
        {/* Tablo container */}
        <table className="article-table">
          <thead>
            <tr>
              <th>Image</th>
              <th>Title</th>
              <th>Description</th>
              <th>Day</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {articles.map((article, index) => (
              <tr key={index}>
                <td>
                  {" "}
                  <img
                    src={`/images/${article.image}`}
                    width={100}
                    alt="resim yüklenemedi"
                  />{" "}
                </td>
                <td>{article.baslik}</td>
                <td>{article.desc}</td>
                <td>{article.day}</td>
                <td>{article.name}</td>
                <td>
                 
                  <Button className="btn btn-danger" onClick={() => deleteUser(article.id, article.image)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Ekleme Formu */}
      </div>
      
    </div>
  );
};

export default BlogTable;
