/* import React, { useState } from 'react';

function AdminGaleri() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [youtubeLink, setYoutubeLink] = useState('');
    const [galleryImages, setGalleryImages] = useState([]);
    const [galleryVideos, setGalleryVideos] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [editType, setEditType] = useState(null); // image veya video

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleYoutubeLinkChange = (e) => {
        setYoutubeLink(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (selectedFile) {
            // Yeni bir resim ekle
            const newItem = {
                type: 'image',
                image: selectedFile
            };
            setGalleryImages([...galleryImages, newItem]);
        } else if (youtubeLink.trim() !== '') {
            // Yeni bir video ekle
            const newItem = {
                type: 'video',
                youtubeLink: youtubeLink
            };
            setGalleryVideos([...galleryVideos, newItem]);
        }

        // Verileri temizle
        setSelectedFile(null);
        setYoutubeLink('');
    };

    const handleEdit = (index, type) => {
        // Düzenlemeyi başlatın
        setEditIndex(index);
        setEditType(type);
        const itemToEdit = type === 'image' ? galleryImages[index] : galleryVideos[index];
        if (type === 'image') {
            setSelectedFile(itemToEdit.image);
        } else {
            setYoutubeLink(itemToEdit.youtubeLink);
        }
    };

    const handleSaveEdit = () => {
        // Düzenlemeyi kaydet
        if (editType === 'image') {
            const updatedItems = [...galleryImages];
            updatedItems[editIndex] = {
                ...updatedItems[editIndex],
                image: selectedFile
            };
            setGalleryImages(updatedItems);
        } else {
            const updatedItems = [...galleryVideos];
            updatedItems[editIndex] = {
                ...updatedItems[editIndex],
                youtubeLink: youtubeLink
            };
            setGalleryVideos(updatedItems);
        }

        // Düzenleme işlemini sıfırla
        setEditIndex(null);
        setEditType(null);
        setSelectedFile(null);
        setYoutubeLink('');
    };

    const handleDelete = (index, type) => {
        // Silme işlemini gerçekleştirin
        if (type === 'image') {
            const updatedItems = [...galleryImages];
            updatedItems.splice(index, 1);
            setGalleryImages(updatedItems);
        } else {
            const updatedItems = [...galleryVideos];
            updatedItems.splice(index, 1);
            setGalleryVideos(updatedItems);
        }
    };

    return (
        <div className="container">
            <h1>Admin Panel - Galeri ve Fotoğraf Yöneticisi</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="fileInput" className="form-label">Fotoğraf Seç</label>
                    <div className="input-group">
                        <input type="file" className="form-control" id="fileInput" accept="image/*" onChange={handleFileChange} />
                        <button type="button" className="btn btn-outline-primary" onClick={handleSubmit}>Ekle</button>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="youtubeLinkInput" className="form-label">YouTube Video Linki</label>
                    <div className="input-group">
                        <input type="text" className="form-control" id="youtubeLinkInput" value={youtubeLink} onChange={handleYoutubeLinkChange} />
                        <button type="button" className="btn btn-outline-primary" onClick={handleSubmit}>Ekle</button>
                    </div>
                </div>
            </form>
            <div className="gallery-container">
                <h2>Fotoğraflar</h2>
                <div className="gallery-images row">
                    {galleryImages.map((item, index) => (
                        <div key={index} className="col-md-4 mb-3">
                            <div className="gallery-item">
                                <img src={URL.createObjectURL(item.image)} alt="Galeri Resmi" className="img-fluid" />
                                <div className="btn-group" role="group">
                                    <button onClick={() => handleEdit(index, 'image')} className="btn btn-sm btn-primary mx-2">Edit</button>
                                    <button onClick={() => handleDelete(index, 'image')} className="btn btn-sm btn-danger">Delete</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <h2>Videolar</h2>
                <div className="gallery-videos row">
                    {galleryVideos.map((item, index) => (
                        <div key={index} className="col-md-4 mb-3">
                            <div className="gallery-item">
                                <div className="video-container">
                                    <iframe
                                        width="100%"
                                        height="315"
                                        src={`https://www.youtube.com/embed/${item.youtubeLink}`}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                                <div className="btn-group" role="group">
                                    <button onClick={() => handleEdit(index, 'video')} className="btn btn-sm btn-primary mx-2">Edit</button>
                                    <button onClick={() => handleDelete(index, 'video')} className="btn btn-sm btn-danger">Delete</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AdminGaleri;





 */



import React, { useState } from 'react';
import "./admin-galeri.scss"

function AdminGaleri() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [youtubeLink, setYoutubeLink] = useState('');
    const [galleryImages, setGalleryImages] = useState([]);
    const [galleryVideos, setGalleryVideos] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [editType, setEditType] = useState(null); // image veya video

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleYoutubeLinkChange = (e) => {
        setYoutubeLink(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Burada dosya yükleme ve/veya YouTube linki ekleme işlemleri yapılabilir
        console.log('Seçilen dosya:', selectedFile);
        console.log('YouTube linki:', youtubeLink);
        // Verileri sunucuya gönderme veya başka bir işlem yapma

        // Yeni galeri öğesini ekleyin
        if (selectedFile) {
            const newItem = {
                image: selectedFile
            };
            setGalleryImages([...galleryImages, newItem]);
        } else if (youtubeLink) {
            const newItem = {
                youtubeLink: youtubeLink
            };
            setGalleryVideos([...galleryVideos, newItem]);
        }

        // Verileri temizle
        setSelectedFile(null);
        setYoutubeLink('');
    };

    const handleEdit = (index, type) => {
        // Düzenlemeyi başlatın
        setEditIndex(index);
        setEditType(type);
        const itemToEdit = type === 'image' ? galleryImages[index] : galleryVideos[index];
        if (type === 'image') {
            setSelectedFile(itemToEdit.image);
        } else {
            setYoutubeLink(itemToEdit.youtubeLink);
        }
    };

    const handleSaveEdit = () => {
        // Düzenlemeyi kaydet
        const updatedItems = editType === 'image' ? [...galleryImages] : [...galleryVideos];
        updatedItems[editIndex] = {
            image: editType === 'image' ? selectedFile : galleryImages[editIndex].image,
            youtubeLink: editType === 'video' ? youtubeLink : galleryVideos[editIndex].youtubeLink
        };

        if (editType === 'image') {
            setGalleryImages(updatedItems);
        } else {
            setGalleryVideos(updatedItems);
        }

        // Düzenleme işlemini sıfırla
        setEditIndex(null);
        setEditType(null);
        setSelectedFile(null);
        setYoutubeLink('');
    };

    const handleDelete = (index, type) => {
        // Silme işlemini gerçekleştirin
        if (type === 'image') {
            const updatedItems = [...galleryImages];
            updatedItems.splice(index, 1);
            setGalleryImages(updatedItems);
        } else {
            const updatedItems = [...galleryVideos];
            updatedItems.splice(index, 1);
            setGalleryVideos(updatedItems);
        }
    };

    return (
        <div className="container">
            <h1>Admin Panel - Galeri ve Fotoğraf Yöneticisi</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="fileInput" className="form-label">Fotoğraf Seç</label>
                    <div className="input-group">
                        <input type="file" className="form-control" id="fileInput" accept="image/*" onChange={handleFileChange} />
                        <button type="button" className="btn btn-outline-primary" onClick={handleSubmit}>Ekle</button>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="youtubeLinkInput" className="form-label">YouTube Video Linki</label>
                    <div className="input-group">
                        <input type="text" className="form-control" id="youtubeLinkInput" value={youtubeLink} onChange={handleYoutubeLinkChange} />
                        <button type="button" className="btn btn-outline-primary" onClick={handleSubmit}>Ekle</button>
                    </div>
                </div>
            </form>
            <div className="gallery-container">
                {galleryImages.length > 0 && (
                    <div className="gallery-section">
                        <h2>Fotoğraflar</h2>
                        <div className="row">
                            {galleryImages.map((item, index) => (
                                <div key={index} className="gallery-item col-md-4">
                                    <img src={URL.createObjectURL(item.image)} alt="Galeri Öğesi" style={{ maxWidth: '100%' }} />
                                    <div className="btn-group" role="group">
                                        <button onClick={() => handleEdit(index, 'image')} className="btn btn-sm btn-primary mx-2">Edit</button>
                                        <button onClick={() => handleDelete(index, 'image')} className="btn btn-sm btn-danger">Delete</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {galleryVideos.length > 0 && (
                    <div className="gallery-section">
                        <h2>Videolar</h2>
                        <div className="row">
                            {galleryVideos.map((item, index) => (
                                <div key={index} className="gallery-item col-md-4">
                                    <div className="video-container">
                                        <iframe
                                            width="100%"
                                            height="auto"
                                            src={`https://www.youtube.com/embed/${item.youtubeLink}`}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                    <div className="btn-group" role="group">
                                        <button onClick={() => handleEdit(index, 'video')} className="btn btn-sm btn-primary mx-2">Edit</button>
                                        <button onClick={() => handleDelete(index, 'video')} className="btn btn-sm btn-danger">Delete</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            {editIndex !== null && (
                <div className="modal" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit</h5>
                                <button type="button" className="btn-close" aria-label="Close" onClick={() => setEditIndex(null)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    {editType === 'image' && (
                                        <div className="mb-3">
                                            <label htmlFor="editFileInput" className="form-label">Fotoğraf Seç</label>
                                            <input type="file" className="form-control" id="editFileInput" accept="image/*" onChange={handleFileChange} />
                                        </div>
                                    )}
                                    {editType === 'video' && (
                                        <div className="mb-3">
                                            <label htmlFor="editYoutubeLinkInput" className="form-label">YouTube Video Linki</label>
                                            <input type="text" className="form-control" id="editYoutubeLinkInput" value={youtubeLink} onChange={handleYoutubeLinkChange} />
                                        </div>
                                    )}
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setEditIndex(null)}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={handleSaveEdit}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AdminGaleri;

