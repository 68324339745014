import React from 'react'
import Hakkimda from '../components/hakkimda/hakkimda'

const HakkimdaPage = () => {
  return (
    <>
   
    <Hakkimda/>
    </>
  )
}

export default HakkimdaPage