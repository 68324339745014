import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "./yenilikler.scss";
import { FaArrowAltCircleRight } from "react-icons/fa";
import axios from "axios";
import { config } from "../../helpers/config";


const API_URL = config.api.baseUrl;


const Yenilikler = () => {

  const [yenilikler, setYenilikler] = useState([]);
  useEffect(() => {
    getYenilikler();
  }, []);

  function getYenilikler() {
    axios.get(`${API_URL}/?type=yenilikler`).then(function (response) {
      console.log(response.data);
      setYenilikler(response.data);
    });
  }





  return (
    <div className="tabs">
      <Container>
        <h4>Son Teknolojik Cihaz ve Yöntemler</h4>
        <br />
        <Row xs={1} className="mobil-ekran">
          {yenilikler.map((yenilik, id) => (
            <Col key={id} className="image d-flex" md={12}>
              <Image
                style={{ marginTop: "10px", width: "360px", height: "350px" }}
                src={`/images/${yenilik.image}`}
              />
              <h4 className="baslik-title">{yenilik.title}</h4>
              <div
                className="icons"
                style={{ fontSize: "40px", marginTop: "130px" }}
              >
                {" "}
                <FaArrowAltCircleRight />{" "}
              </div>

              <Col md={6}>
                <h5>{yenilik.description}</h5>
              </Col>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Yenilikler;
