import React from 'react'
import BeforeAfter from './before-after'
import hoca from "../../helpers/data/hoca.json"
import { Col, Container, Row } from 'react-bootstrap'
import "./hoca.scss"


const Hoca = () => {
  return (
    <div className="hoca">
    <Container>
      <Row className="g-5">
        <Col lg={12}>
          <h2 className="title"> DOKTOR BİLGİLERİ </h2>
        </Col>

        {hoca.map((item) => (
          <Col key={item.id}>
            <BeforeAfter {...item} />
      
          </Col>
        ))}
      </Row>
    </Container>
  </div>
  )
}

export default Hoca