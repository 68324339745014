import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import "./dashboard-page.scss"
import LoginForm from './login-form.jsx';
import AdminsGaleri from './admins-galeri.jsx';
import BlogTable from './blog-table.jsx';
import AdminYenilikler from './admin-yenilikler.jsx';

const Dashboard = () => {


  const [value, setValue] = useState(
    sessionStorage.getItem("isLoggedIn") === "true" ? "true" : ""
  );


  const navigate = useNavigate();

  useEffect(() => {
    if (value !== "true") {
      navigate("/login");
      return;
    }
  }, []);

  return (
   
    <div className='text-center' style={{fontSize:"35px", marginTop:"130px",marginBottom:"130px"}}>
     <Container className='text-center' style={{fontSize:"35px", marginTop:"40px"}}>
      <Row>
        <Col>
          <Card bg="primary" text="white" className="text-center" >
            <Card.Body>
              <Card.Title>
                <Link to="/dashboard/admin-galeri" className="text-white text-link">Fotoğraf ve Video Admin Yöneticisi</Link>
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card bg="success" text="white" className="text-center">
            <Card.Body>
              <Card.Title>
                <Link to="/dashboard/blog-table" className="text-white text-link">Blog (Makale) Admin Yöneticisi</Link>
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card bg="danger" text="white" className="text-center">
            <Card.Body>
              <Card.Title>
                <Link to="/dashboard/admin-yenilikler" className="text-white text-link">Yenilikler Admin Yöneticisi</Link>
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
    </Container>
   
  </div>
   
  );
};

export default Dashboard;
 




/* import React from 'react'
import { Container, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const DashboardPage = (props) => {
  return (
    <Nav {...props} >
   
    
    <Nav.Link as={Link} to="/admin-galeri" style={{color:"#ddac43", fontWeight:"700", fontSize:"23px", paddingLeft:"30px"}}>
       Galeri
    </Nav.Link>
    <Nav.Link as={Link} to="/admin-blog" style={{color:"#ddac43", fontWeight:"700", fontSize:"23px", paddingLeft:"30px"}}>
       Blog
    </Nav.Link>
    <Nav.Link as={Link} to="/admin-yenilik" style={{color:"#ddac43", fontWeight:"700", fontSize:"23px", paddingLeft:"30px"}}>
       Yenilikler
    </Nav.Link>
  
  </Nav>
  )
}

export default DashboardPage */