import React, { useState } from 'react';
import { config } from '../../../helpers/config';
import axios from 'axios';

const API_URL = config.api.baseUrl;

function VideoAddForm({ onVideoAdd }) {
    const [youtubeLink, setYoutubeLink] = useState('');

    const handleYoutubeLinkChange = (e) => {
        setYoutubeLink(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const item = {
            id: 1,
            url: youtubeLink
        }

        const secondResponse = await axios.post(`${API_URL}/?type=insert_video`, item);
            console.log(secondResponse.data);

        onVideoAdd(youtubeLink);
        setYoutubeLink('');
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="youtubeLinkInput" className="form-label">YouTube Video Linki</label>
                <div className="input-group">
                    <input type="text" className="form-control" id="youtubeLinkInput" value={youtubeLink} onChange={handleYoutubeLinkChange} />
                    <button type="submit" className="btn btn-outline-primary">Ekle</button>
                </div>
            </div>
        </form>
    );
}

export default VideoAddForm;
