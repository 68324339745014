import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./resents.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import EventCard from "../events/event-card";
import axios from "axios";
import { config } from "../../helpers/config";


const API_URL = config.api.baseUrl;

const Resents = () => {
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);

  function getUsers() {
    axios.get(`${API_URL}/?type=articles`).then(function (response) {
      console.log(response.data);
      setArticles(response.data);
    });
  }

  return (
    <div className="upcoming-events">
      <Container>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          modules={[Navigation]}
          navigation={{
            nextEl: ".next",
            prevEl: ".prev",
          }}
          breakpoints={{
            576: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 3,
            },
            1400: {
              slidesPerView: 4,
            },
          }}
        >
          {articles.map((item) => (
            <SwiperSlide key={item.id}>
              <EventCard {...item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </div>
  );
};

export default Resents;
