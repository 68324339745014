import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { Container } from 'react-bootstrap';
import "./resents-blogs.scss"

const ResentsBlogs = () => {
  return (
    <div>
         <div className="events">
        <Container >
        <h2>
          <div className="prev">
            <FaChevronCircleLeft />
          </div>
          <div className="next">
            <FaChevronCircleRight />
          </div>
        </h2>

        <Swiper
        
          slidesPerView={1}
          spaceBetween={30}
          modules={[Navigation]}
          navigation={{
            nextEl: ".next",
            prevEl: ".prev",
           
          }}
          breakpoints={{
            576: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 3,
            },
            1400: {
              slidesPerView: 4,
            },
          }}
          
        ></Swiper>
    </Container>
    </div>
    </div>
  )
}

export default ResentsBlogs