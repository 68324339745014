import React, { useState } from 'react';
import axios from 'axios';
import { config } from '../../../helpers/config';

const API_URL = config.api.baseUrl;

function PhotoUploadForm({ onPhotoAdd }) {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedFile) {
            console.log('Lütfen bir dosya seçin.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);


        try {
            const response = await axios.post(`${API_URL}/?type=upload_photo`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            onPhotoAdd(selectedFile);
            setSelectedFile(null);

            // İkinci axios isteği
            const photosPath = {
                id: 17,
                image: selectedFile.name
            };

            const secondResponse = await axios.post(`${API_URL}/?type=insert_photo`, photosPath);
            console.log(secondResponse.data);
        

        } catch (error) {
            console.error('Dosya yüklenirken veya path dbye yazılırken bir hata oluştu:...', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="fileInput" className="form-label">Fotoğraf Seç</label>
                <div  className="input-group">
                <input type="file" className="form-control" id="fileInput" accept="image/*" onChange={handleFileChange} />
                <button type="submit" className="btn btn-outline-primary">Ekle</button>
                </div>
            </div>
            
        </form>
    );
}

export default PhotoUploadForm;
