import React from "react";
import Slider from "../components/home/slider";
import Spacer from "../components/common/spacer";
import Welcome from "../components/about/welcome";
import Quality from "../components/common/quality";
import Hoca from "../components/common/hoca";
import Resents from "../components/common/resents";
import BackgroundLogo from "../components/common/background-logo";
import Yenilikler from "../components/yenilikler/yenilikler";
import ResentsBlog from "../components/common/resents-blog";


const HomePage = () => {
  
  return (
    <>
   
      <BackgroundLogo />
      <Slider />
      <Quality />
      <Welcome />
      <Spacer />
      <Yenilikler />
      <Spacer />
      <Hoca />
      <Spacer />
      <ResentsBlog />
      <Resents />
      <Spacer />
    </>
  );
};

export default HomePage;
