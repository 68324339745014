import React from 'react'
import Yenilikler from '../components/yenilikler/yenilikler'
import BackgroundLogo from '../components/common/background-logo'
import Spacer from '../components/common/spacer'
import PageHeader from '../components/common/page-header'


const YeniliklerPage = () => {
  return (
    <>

    <PageHeader title="YENİLİKLER" />
    <BackgroundLogo/>
    <Spacer/>
    <Yenilikler/>
    <Spacer/>
    </>
  )
}

export default YeniliklerPage