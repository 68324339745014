import React, { useEffect, useState } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import PageHeader from "../common/page-header";
import { FaArrowAltCircleRight } from "react-icons/fa";
import "./fotograflar.scss";
import BackgroundLogo from "../common/background-logo";
import axios from "axios";
import { Link } from "react-router-dom";
import { config } from "../../helpers/config";

const API_URL = config.api.baseUrl;

const Fotograflar = () => {

  const [galerifoto, setPhotos] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);

  function getUsers() {
    axios.get(`${API_URL}/?type=photos`).then(function (response) {
      console.log(response.data);
      setPhotos(response.data);
    });
  }



  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <PageHeader title="FOTOĞRAFLAR" />
      <BackgroundLogo />
      <div className="text-center">
      <Link to="/galeri">GALERİ</Link>
        <span className="mx-2">
         <FaArrowAltCircleRight />
        </span>{" "}
        <Link to="/videos">VİDEOLAR</Link>
      </div>
      <Container>
        <Row>
          <Col className="foto mt-5 text-center">
            <div>
              {/* Fotoğrafların listesi */}
              {galerifoto.map((foto, index) => (
                <img
                  key={index}
                  src={`/images/${foto.image}`}
                  width={300}
                  alt={`Fotoğraf ${index + 1}`}
                  onClick={() => openModal(foto)}
                  style={{ cursor: "pointer" }}
                />
              ))}
            </div>
          </Col>
        </Row>
      </Container>

      {/* Büyük resim modalı */}
      <Modal show={!!selectedImage} onHide={closeModal} centered>
        <Modal.Body className="text-center">
          <img
            src={`/images/${selectedImage && selectedImage.image}`}
            alt="Büyük Fotoğraf"
            style={{ maxWidth: "100%", maxHeight: "70vh", objectFit: "contain"  }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Fotograflar;
