import React, { useState, useEffect } from "react";
import axios from "axios";
import { config } from "../../../helpers/config";

const API_URL = config.api.baseUrl;
function FotoList() {
  const [galerifoto, setFotoList] = useState([]);

  useEffect(() => {
    getFotoList();
  }, []);

  function getFotoList() {
    axios.get(`${API_URL}/?type=photos`).then(function (response) {
      console.log(response.data);
      setFotoList(response.data);
    });
  }

  const handleDelete = async (item) => {
    try {
      const response = await axios.post(
        `${API_URL}/?type=remove_photo&param=${item.image}`
      );
      console.log(response.data);

      const secondResponse = await axios.delete(
        `${API_URL}/?type=photos&param=${item.id}`
      );
      getFotoList();
      console.log(secondResponse.data);
    } catch (error) {
      console.error(
        "Dosya yüklenirken veya path dbye yazılırken bir hata oluştu:...",
        error
      );
    }
  };

  return (
    <div className="gallery-container">
      {galerifoto.length > 0 && (
        <div className="gallery-section">
          <h2>Fotoğraflar</h2>
          <div className="row">
            {galerifoto.map((item, index) => (
              <div key={index} className="gallery-item col-md-4">
                <img
                  src={`/images/${item.image}`}
                  alt="Galeri Öğesi"
                  style={{ maxWidth: "100%" }}
                />
                <div className="btn-group" role="group">
                  <button
                    onClick={() => handleDelete(item)}
                    className="btn btn-sm btn-danger"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default FotoList;
