export const config = {
  project: {
    name: "Ayfer ŞEN ACAR",
    slogan:
      "Sağlığınıza Özel Cerrahi Çözümler",
    description:
      " Sağlığınızın her aşamasında yanınızda olan ekibimiz, kişiye özel cerrahi çözümlerle hizmet vermektedir.",
    version: "1.0.0",
  },
  contact: {
    phone1: "+90 (539) 602 93 37",
    phone2: "+90 (342) 503 30 00",
    email: "ayfer_sen_@windowslive.com",
    address: "15 Temmuz Mahallesi Alparslan Türkeş Caddesi No:44 ŞEHİTKAMİL/GAZİANTEP",
    mapURL: "https://maps.app.goo.gl/JPpvPd8zuTvWXm4f9",
    mapEmbedURL:
      "https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d1592.1681107980808!2d37.32172550807342!3d37.04947018575526!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x1531e1ac2bd04987%3A0xb976d1ff1c8614e!2sOp.%20Dr.%20Medine%20Konca%2015%20Temmuz%20Mahallesi%20Alparslan%20T%C3%BCrke%C5%9F%20Caddesi%20No%20%3A%2044%2027580%20%C5%9Eehitkamil%2FGaziantep!3m2!1d37.049513!2d37.321108599999995!5e0!3m2!1str!2str!4v1708275488378!5m2!1str!2str",
    socialMedia: {
      twitter: "https://twitter.com",
      facebook: "https://facebook.com",
      instagram: "https://www.instagram.com/op.dr.ayfersenacar?igsh=MWZmNWE4dzZsZms3bA==",
      youtube: "https://youtube.com",
    },
  },
  api: {
    baseUrl: "https://ayfersenacar.com/api",
  },
  pageRoles: {
    dashboard: ["ADMİN"]
  }
};


