import React from "react";
import Resents from "../components/common/resents";
import BackgroundLogo from "../components/common/background-logo";
import Spacer from "../components/common/spacer";
import PageHeader from "../components/common/page-header";
import ResentsBlogs from "../components/resents/resents-blogs";


const ResentsPage = () => {
  return (
    <>
   
      <BackgroundLogo />
      <PageHeader title="BLOG" />
      <ResentsBlogs/>
      <Resents />
      <Spacer />
    </>
  );
};

export default ResentsPage;
