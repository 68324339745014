/* import React, { useState } from 'react';
import PageHeader from '../../common/page-header';
import { Button, Container } from 'react-bootstrap';
import axios from 'axios';
import { config } from '../../../helpers/config';


const API_URL = config.api.baseUrl;

const AdminYenilikler = () => {
  // State for managing added content
  const [content, setContent] = useState({
    title: '',
    description: '',
    image:""
  }
  );
  // State for managing file input
  const [selectedFile, setSelectedFile] = useState(null);
  // State for managing text input

  // Function to handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Function to handle text input change
  const handleSubmit = (event) => {
    setContent(event.target.value);
  };

  // Function to handle adding content
  const handleAddContent = async () => {
      e.preventDefault();

      if (!selectedFile) {
          console.log('Lütfen bir dosya seçin.');
          return;
      }

      const formData = new FormData();
      formData.append('file', selectedFile);


      try {
          const response = await axios.post(`${API_URL}/?type=upload_photo`, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });
          console.log(response.data);

          const secondResponse = await axios.post(`${API_URL}/?type=insert_yenilik`, content);
          console.log(secondResponse.data);
      

      } catch (error) {
          console.error('Dosya yüklenirken veya path dbye yazılırken bir hata oluştu:...', error);
      }
  };

  // Function to handle content deletion
  const handleDeleteContent = (id) => {
    const updatedContentList = contentList.filter(content => content.id !== id);
    setContentList(updatedContentList);
  };

  return (
    <div>
      <PageHeader title="ADMİN YENİLİKLER " />
      <Container>
      <h1>Admin Panel - Yenilikler </h1>
      <h2>Add Image</h2>
      <div style={{ marginBottom: '10px' }}>
        <input id="fileInput" type="file" onChange={handleFileChange} style={{ marginBottom: '10px' }} />
        <div>
          <h3>Text Input:</h3>
        <input type="text"  value={textInput} onChange={handleAddContent} style={{ width: '90%', marginBottom: '10px', fontSize: '18px' }} />
        <Button onClick={handleSubmit}>Add</Button>
        </div>
       
      </div>

      <h2>Content List</h2>
      <div>
        {contentList.map(content => (
          <div key={content.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <img src={URL.createObjectURL(content.file)} alt="Content" style={{ maxWidth: '100%', maxHeight: '100px', marginRight: '10px' }} />
            <p style={{ fontSize: '18px', flexGrow: 1 }}>{content.text}</p>
            <Button className='btn-danger' onClick={() => handleDeleteContent(content.id)}>Delete</Button>
          </div>
        ))}
      </div>
      </Container>
    </div>
  );
};

export default AdminYenilikler;
 */

import React, { useEffect, useState } from "react";
import "./blog-tabs.scss";
import PageHeader from "../../common/page-header";
import axios from "axios";
import { config } from "../../../helpers/config";
import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";

const API_URL = config.api.baseUrl;

const AdminYenilikler = () => {
  // Makalelerin tutulacağı state
  /*   const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
};
 */
  const [selectedFile, setSelectedFile] = useState(null);
  const [articles, setArticles] = useState([]);
  const [value, setValue] = useState(
    sessionStorage.getItem("isLoggedIn") === "true" ? "true" : ""
  );
  const [editIndex, setEditIndex] = useState(null);
  const [newArticle, setNewArticle] = useState({
    title: "",
    description: "",
    image: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (value !== "true") {
      navigate("/login");
      return;
    }
  }, []);


  

  // Eklenecek yeni makalenin geçici olarak tutulacağı state

  // Düzenleme yapılacak makalenin index değerini tutacak state

  useEffect(() => {
    getUsers();
  }, []);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setNewArticle((prevArticle) => ({
      ...prevArticle,
      image: e.target.files[0].name,
    }));
  };

  const deleteUser = async (id, imageName) => {
    try {
      const response = await axios.post(
        `${API_URL}/?type=remove_photo&param=${imageName}`
      );
      console.log(response.data);

      const secondResponse = await axios.delete(
        `${API_URL}/?type=yenilikler&param=${id}`
      );
      getUsers();
      console.log(secondResponse.data);
    } catch (error) {
      console.error(
        "Dosya yüklenirken veya path dbye yazılırken bir hata oluştu:...",
        error
      );
    }
  };

  function getUsers() {
    axios.get(`${API_URL}/?type=yenilikler`).then(function (response) {
      console.log(response.data);
      setArticles(response.data);
    });
  }

  // Yeni makale eklemek için
  const addArticle = async () => {
    if (!selectedFile) {
      console.log("Lütfen bir dosya seçin.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(
        `${API_URL}/?type=upload_photo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      /*          onPhotoAdd(selectedFile);
       */

      const secondResponse = await axios.post(
        `${API_URL}/?type=insert_yenilik`,
        newArticle
      );
      console.log(secondResponse.data);
      getUsers();
      setSelectedFile(null);

      setNewArticle({
        // Inputları boşalt

        title: "",
        description: "",
        image: "",
      });

      window.location.reload();
    } catch (error) {
      console.error(
        "Dosya yüklenirken veya path dbye yazılırken bir hata oluştu:...",
        error
      );
    }
  };

  // Makale düzenlemek için
  const editArticle = (index) => {
    // Düzenleme yapılacak makalenin index değerini set et
    setEditIndex(index);
    // Düzenleme yapılacak makalenin bilgilerini geçici makaleye set et
    setNewArticle(articles[index]);
  };

 

  // Makaleyi güncellemek için
  const updateArticle = () => {
    // Düzenleme yapılan makaleyi güncelle
    articles[editIndex] = newArticle;
    // Güncellenmiş makale listesini set et
    setArticles([...articles]);
    // Düzenleme yapılacak makalenin index değerini sıfırla
    setEditIndex(null);
    // Geçici makale bilgilerini sıfırla
    axios
      .put(`${API_URL}/articles/${newArticle.id}/edit`, newArticle)
      .then(function (response) {
        console.log(response.data);
        getUsers();
      });
  };

  return (
    <div className="text-center">
      <PageHeader title="ADMİN YENİLİKLER" />
      <div className="article-form">
        <input
          type="file"
          id="fileInput"
          accept="image/*"
          onChange={handleFileChange}
        />
        <input
          type="text"
          placeholder="Title"
          value={newArticle.title}
          onChange={(e) =>
            setNewArticle({ ...newArticle, title: e.target.value })
          }
        />
        <input
          type="text"
          placeholder="Description"
          value={newArticle.description}
          onChange={(e) =>
            setNewArticle({ ...newArticle, description: e.target.value })
          }
        />

        {editIndex === null ? (
          <button style={{ backgroundColor: "green" }} onClick={addArticle}>
            Add
          </button>
        ) : (
          <button onClick={updateArticle}>Update</button>
        )}
      </div>

      <div className="article-table-container">
        {" "}
        {/* Tablo container */}
        <table className="article-table">
          <thead>
            <tr>
              <th>Image</th>
              <th>Title</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {articles.map((article, index) => (
              <tr key={index}>
                <td>
                  {" "}
                  <img
                    src={`/images/${article.image}`}
                    width={100}
                    alt="resim yüklenemedi"
                  />{" "}
                </td>
                <td>{article.title}</td>
                <td>{article.description}</td>
                <td>
                  {/*                 <button  onClick={() => editArticle(index)}>Edit</button>
                   */}{" "}
                  <Button className="btn btn-danger" onClick={() => deleteUser(article.id, article.image)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Ekleme Formu */}
      </div>
      
    </div>
  );
};

export default AdminYenilikler;
