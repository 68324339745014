import React, { useEffect, useState } from 'react';
import PhotoUploadForm from './photo-upload-form';
import VideoAddForm from "./video-add-from";
import FotoList from './foto-list';
import VideoList from './video-list';
import { useNavigate } from 'react-router';
import { Button } from 'react-bootstrap';
import PageHeader from '../../common/page-header';


function AdminsGaleri() {
    const [galleryImages, setGalleryImages] = useState([]);
    const [galleryVideos, setGalleryVideos] = useState([]);
    const [value, setValue] = useState(
        sessionStorage.getItem("isLoggedIn") === "true" ? "true" : ""
      );
      const navigate = useNavigate();

      useEffect(() => {
        if (value !== "true") {
          navigate("/login");
          return;
        }
      }, []);

    

    const handlePhotoAdd = (newPhoto) => {
        const newItem = {
            image: newPhoto
        };
        setGalleryImages([...galleryImages, newItem]);
        window.location.reload()
    };

    const handleVideoAdd = (youtubeLink) => {
        const newItem = {
            youtubeLink: youtubeLink
        };
        setGalleryVideos([...galleryVideos, newItem]);
        window.location.reload()
    };

    // Diğer fonksiyonlar ve JSX içeriği burada devam eder

    return (
       <div>
        <PageHeader title="Admin - Galeri ve Fotoğraf Yöneticisi" />
         <div className="container">
            
            <PhotoUploadForm onPhotoAdd={handlePhotoAdd} />
            <VideoAddForm onVideoAdd={handleVideoAdd} />
            <FotoList/>
            <VideoList/>
            {/* Diğer JSX içeriği burada devam eder */}
            
        </div>
       </div>
    );
}

export default AdminsGaleri;
