import React from "react";
import { Button, Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const MainMenu = (props) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    navigate("/login");
    sessionStorage.setItem("isLoggedIn", "false");
  };

  return (
    <Nav {...props}>
      <Nav.Link
        as={Link}
        to="/"
        style={{
          color: "#ddac43",
          fontWeight: "700",
          fontSize: "23px",
          paddingLeft: "30px",
        }}
      >
        Anasayfa
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/galeri"
        style={{
          color: "#ddac43",
          fontWeight: "700",
          fontSize: "23px",
          paddingLeft: "30px",
        }}
      >
        Galeri
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/yenilikler"
        style={{
          color: "#ddac43",
          fontWeight: "700",
          fontSize: "23px",
          paddingLeft: "30px",
        }}
      >
        Yenilikler
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/blog"
        style={{
          color: "#ddac43",
          fontWeight: "700",
          fontSize: "23px",
          paddingLeft: "30px",
        }}
      >
        Blog
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/hakkimda"
        style={{
          color: "#ddac43",
          fontWeight: "700",
          fontSize: "23px",
          paddingLeft: "30px",
        }}
      >
        Hakkkımda
      </Nav.Link>
      <div style={{ marginLeft: "30px", marginTop: "5px", marginBottom:"10px" }}>
        {sessionStorage.getItem("isLoggedIn") === "true" && (
          <Button onClick={handleLogout}> LOGOUT </Button>
        )}
      </div>
    </Nav>
  );
};

export default MainMenu;
