import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { config } from '../../../helpers/config';

const API_URL = config.api.baseUrl;

function VideoList() {
    const [galleryVideos, setGalleryVideos] = useState([]);

    useEffect(() => {
        getVideos();
    }, []);

    function getVideos() {
        axios.get(`${API_URL}/?type=videos`).then(function (response) {
            console.log(response.data);
            setGalleryVideos(response.data);
        });
    }

    const handleDelete = async (item) => {
        const secondResponse = await axios.delete(
            `${API_URL}/?type=videos&param=${item.id}`
          );
          getVideos();
          console.log(secondResponse.data);    
        };
     console.log(galleryVideos);

    return (
        <div className="gallery-section">
            <h2>Videolar</h2>
            <div className="row">
                {galleryVideos.map((item, index) => (
                    <div key={index} className="gallery-item col-md-4">
                        <div className="video-container">
                            <iframe
                                width="100%"
                                height="250"
                                src={`https://www.youtube.com/embed/${item.url.split("v=")[1]}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                        <div className="btn-group" role="group">
                            <button onClick={() => handleDelete(item)} className="btn btn-sm btn-danger">Delete</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default VideoList;
