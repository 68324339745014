import React from 'react'
import Iletisim from '../components/bize-ulasin/iletisim'
import BackgroundLogo from '../components/common/background-logo'
import Map from '../components/bize-ulasin/map'
import Spacer from '../components/common/spacer'


const BizeUlasin = () => {
  return (
    <>

    <BackgroundLogo/>
    <Iletisim/>
    <Map/>
    <Spacer/>  
    </>
  )
}

export default BizeUlasin