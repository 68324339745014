import React, { useEffect, useState } from "react";
import "./gallery.scss";
import PageHeader from "../common/page-header";
import { FaArrowAltCircleRight } from "react-icons/fa";
import BackgroundLogo from "../common/background-logo";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import Youtube from "./youtube";
import { Link } from "react-router-dom";
import { config } from "../../helpers/config";


const API_URL = config.api.baseUrl;

const Videolar = () => {

  const [youtubeLinkList, setyoutubeLinkList] = useState([]);
  useEffect(() => {
    getVideos();
  }, []);

  function getVideos() {
    axios.get(`${API_URL}/?type=videos`).then(function (response) {
      console.log(response.data);
      setyoutubeLinkList(response.data);
    });
  }


  return (
    <div>
      <PageHeader title="VİDEOLAR" />
      <BackgroundLogo/>
      <div className='text-center'>
      
      <Link to="/galeri">GALERİ</Link>  <span className="mx-2"> <FaArrowAltCircleRight /></span><Link to="/photos">FOTOĞRAFLAR</Link>
      </div>
      {/* YouTube videosunu gömün */}
      <div className="text-center" >
     <Container>
     <Row>
          {youtubeLinkList.map((item) => (
              <Col key={item.id} className='d-flex mt-2' xs={12} lg={6}>
                <Youtube youtubeVideoLink={item.url}/>
              </Col>
            ))}
          </Row>
     </Container>
       
      </div>
    </div>
  );
};

// YouTube video linkinden embed kodunu almak için yardımcı fonksiyon
const getYouTubeEmbedCode = (videoLink) => {
  const videoId = videoLink.split("v=")[1];
  return `<iframe width="460" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
};

export default Videolar;
