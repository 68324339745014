import React from "react";
import { Card, Image } from "react-bootstrap";

import "./before-after.scss";

const BeforeAfter = ({ title, desc, image }) => {
  return (
    <div className="before-after">
      <Card>
        <div className="hoca" >
          <Card.Img src={`/images/hoca/${image}`} />
        </div>

        <Card.Title>
          <Image style={{ width: "80px"}} src="/images/logo/logo.jpg" />
          <h3>{title}</h3>
          <h5>{desc}</h5>
        </Card.Title>
      </Card>
    </div>
  );
};

export default BeforeAfter;
